// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// define baseUrl and fileServer based on the current location
// (.*)bo.maisondumenuisier.fr$ => https://$1api.maisondumenuisier.fr
// if localhost then https://$1api.maisondumenuisier.fr

const currentUrl = window.location.hostname;

// let baseURL = 'https://preprod-api.maisondumenuisier.fr/nocache';
// let fileServer = 'https://preprod-fs.maisondumenuisier.fr/';

let baseURL = 'https://preprod-api.maisondumenuisier.fr/nocache';
let fileServer = 'https://preprod-fs.maisondumenuisier.fr/';
let S3_SCREENSHOT_PUBLIC_URL = 'https://config-3d-screenshot.s3.gra.io.cloud.ovh.net/';

const matches = /^(.*)bo.maisondumenuisier.fr$/.exec(currentUrl);
if (matches) {
    baseURL = `https://${matches[1]}api.maisondumenuisier.fr/nocache`;
    fileServer = `https://${matches[1]}fs.maisondumenuisier.fr/`;
}

export const environment = {
    production: false,
    baseURL,
    fileServer,
    S3_SCREENSHOT_PUBLIC_URL,
    firebase: {
        apiKey: "Your Api Key",
        authDomain: "Your Auth Domain",
        databaseURL: "Your Database Url",
        projectId: "Your Project Id",
        storageBucket: "Your StorageBucket url",
        messagingSenderId: "Your Sender Id"
    }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
